<template>
  <div>
    <v-app id="app">
      <div id="root">
        <AlertBanner v-if="isLoggedInAsCustomer" :text="translate('fe_app_logged_in_as', customerProfile.email)"/>
        <RouterView />
        <Overlay/>
        <IconSetHeart/>
        <IconSetWeb/>
        <Dialog v-if="countrySwitcherData" :title="countrySwitcherTitle" :value="!!countrySwitcherData" @input="countrySwitcherData = null">
          <TheCountrySwitcher :country-switcher-data="countrySwitcherData" @close="countrySwitcherData = null" />
        </Dialog>
        <Dialog :value="authDialogVisible" @input="updateShowLogin($event)">
          <TheAuthenticationWrapper class="flex-grow-1" @success="updateShowLogin(false)"/>
        </Dialog>
      </div>
    </v-app>
    <ThePopoverWrapper :show-nps="false"/>
  </div>
</template>

<script>
import { useCmsBlocks } from '@/stores/cmsBlocks'
import { useCmsVariables } from '@/stores/cmsVariables'
import { mapActions, mapState } from 'pinia'
import { useCustomerStore } from '@/stores/customer'
import { useFeaturesStore } from '@/stores/features'

import IconSetWeb from '@/components/basic/atoms/IconSetWeb'
import IconSetHeart from '@/components/basic/atoms/IconSetHeart'
import Overlay from '@/components/basic/atoms/Overlay'

const ThePopoverWrapper = () => import('@/components/basic/molecules/ThePopoverWrapper')
const TheCountrySwitcher = () => import('@/components/basic/organisms/TheCountrySwitcher')
const AlertBanner = () => import('@/components/basic/atoms/AlertBanner')
const Dialog = () => import('@/components/basic/organisms/Dialog')
const TheAuthenticationWrapper = () => import('@/components/basic/organisms/TheAuthenticationWrapper')

export default {
  name: 'Vicampo',
  components: {
    ThePopoverWrapper,
    TheAuthenticationWrapper,
    IconSetHeart,
    Overlay,
    IconSetWeb,
    TheCountrySwitcher,
    AlertBanner,
    Dialog
  },
  data () {
    return {
      countrySwitcherData: null
    }
  },
  computed: {
    ...mapState(useCustomerStore, ['customerProfile', 'isLoginShowing']),
    isLoggedInAsCustomer () {
      return this.customerProfile.admin_data?.admin_customer_id
    },
    authDialogVisible () {
      return this.isLoginShowing
    },
    countrySwitcherTitle () {
      const switchTo = this.translate('fe_app_country_' + this.countrySwitcherData?.siteId)
      return this.translate('fe_app_country_switcher_header', switchTo)
    },
  },
  methods: {
    ...mapActions(useCustomerStore, ['establishSession', 'showLogin']),
    updateShowLogin (visible) {
      this.showLogin(visible)
    },
  },
  beforeCreate () {
    const cmsBlocks = useCmsBlocks()
    const cmsVariables = useCmsVariables()
    const featuresStore = useFeaturesStore()

    cmsVariables.fetchDefaults()
    cmsBlocks.fetchDefaults()
    featuresStore.refresh()
  },
  async created () {
    await this.establishSession()

    setTimeout(async () => {
      const [
        { default: TagManager },
        { default: SiteConfig },
        { default: CouponHelper },
      ] = await Promise.all([
        await import('@/services/analytics/TagManager'),
        await import('@/services/SiteConfig'),
        await import('@/services/helpers/CouponHelper'),
      ])

      TagManager.pushToDataLayer({ application: 'fe-app' })
      this.countrySwitcherData = await SiteConfig.offerOtherSite()

      // refresh session as soon as the tab becomes active again
      document.addEventListener('visibilitychange', () => {
        if (document.visibilityState !== 'visible') return
        this.establishSession()
      })

      void CouponHelper.checkForCoupon()
    }, 0)
  }
}
</script>


